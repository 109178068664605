<template>
  <div class="yimiao">
    <div>
      <div style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        ">
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%"
            src="../../assets/chuqi.png"
            alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 30px;left:100px">
          <div style="font-size: 18px">出洛人员报备</div>
        </div>
      </div>
      <div>
        <!-- <van-cell-group> -->
        <!-- <van-field v-model="datafrom.OrgName"
            required
            @click="OCodeshow = true"
            readonly="readonly"
            label="社区(村)"
            placeholder="请选择您社区(村)" />
          <van-popup v-model="OCodeshow"
            position="bottom"
            get-container="body">
            <van-cascader title="请选择社区(村)"
              :options="ocodelist"
              @close="OCodeshow = false"
              :field-names="{
                text: 'OrganName',
                value: 'OrganCode',
                children: 'children',
              }"
              @finish="onocode" />
          </van-popup>
        </van-cell-group> -->
        <van-cell-group>
          <van-field v-model="datafrom.OrgName"
            required
            @click="idListOrg = true"
            readonly="readonly"
            label="社区"
            placeholder="请选择社区" />
          <van-popup v-model="idListOrg"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="OrganList"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Region"
            required
            @click="idListRId = true"
            readonly="readonly"
            label="小区"
            placeholder="请选择您所住小区" />
          <van-popup v-model="idListRId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Number"
            label="房号(单位)"
            placeholder="请输入房号(单位)" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Name"
            required
            label=" 姓 名"
            placeholder="请输入姓名" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.IDCard"
            label="身份证号"
            required
            placeholder="请输入身份证号码" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Mobile"
            label="联系电话"
            required
            placeholder="请输入联系电话" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Address"
            required
            label="重点停留地点"
            placeholder="请输入重点停留地点" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.OutTime"
            label="前往时间"
            @click="datafromOutTime = true"
            readonly="readonly"
            required
            placeholder="请选择前往时间" />
          <van-popup v-model="datafromOutTime"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="datetime"
              title="请选择前往时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="datafromOutTime = false"
              @confirm="ondatafromOutTime">
            </van-datetime-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Vehicle"
            label="前往交通工具"
            required
            placeholder="请输入前往交通工具" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.City"
            label="前往目的"
            required
            placeholder="请输入前往目的" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.BackTime"
            label="预计返回时间"
            required
            @click="datafromBackTime = true"
            readonly="readonly"
            placeholder="请选择预计返回时间" />
          <van-popup v-model="datafromBackTime"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="date"
              title="请选择返回时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="datafromBackTime = false"
              @confirm="ondatafromBackTime">
            </van-datetime-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.BackVehicle"
            label="返回交通工具"
            required
            placeholder="请输入返回交通工具" />
        </van-cell-group>
        <van-cell-group>
          <van-cell required
            title="是否有同行人员">
            <van-radio-group v-model="datafrom.IsPeer"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell required
            title="是否核酸通过">
            <van-radio-group v-model="datafrom.IsCheck"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </van-cell>
        </van-cell-group>
        <!-- <van-cell-group>
          <van-cell required
            title="是否有居家隔离条件">
            <van-radio-group v-model="datafrom.IsDivide"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </van-cell>
        </van-cell-group> -->
        <!-- <van-cell-group>
          <van-field v-model="datafrom.Count"
            label="家庭人数"
            required
            placeholder="请输入家庭人数" />
        </van-cell-group> -->
        <!-- <van-cell-group v-if="datafrom.IsDivide == 1">
          <van-field v-model="datafrom.Area"
            label="房屋面积"
            type="number"
            placeholder="请输入房屋面积(平方米)" />
        </van-cell-group> -->
        <!-- <van-cell-group v-if="datafrom.IsDivide == 1">
          <van-cell title="是否独立卫生间">
            <van-radio-group v-model="datafrom.IsAlone"
              direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </van-cell>
        </van-cell-group> -->
        <div class="submit"
          style="padding:10px 7px;">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566;font-size: 14px; ">健康码</span>
        </div>
        <van-uploader v-model="jkImageList"
          multiple
          capture="camera"
          class="imgs"
          :max-count="1"
          :after-read="afterRead"
          :before-delete="jkDelete"
          :deletable="true" />
        <div class="submit"
          style="padding:10px 7px;">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566;font-size: 14px; ">行程码</span>
        </div>
        <van-uploader v-model="xcImageList"
          multiple
          capture="camera"
          class="imgs"
          :max-count="1"
          :after-read="afterXcRead"
          :before-delete="xcDelete"
          :deletable="true" />
        <div class="submit"
          style="padding:10px 7px;">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566;font-size: 14px; ">核酸证明</span>
        </div>
        <van-uploader v-model="hsImageList"
          multiple
          capture="camera"
          class="imgs"
          :max-count="1"
          :after-read="afterhsRead"
          :before-delete="hsDelete"
          :deletable="true" />
      </div>
      <!-- <div class="buttons">
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div> -->
      <div class="buttons">
        <van-button type="info"
          style="margin:10px 0;"
          @click="postfrom()">提交</van-button>
        <van-button style="border: 1px solid #e6e6e6;margin:10px 0;"
          type="
          default"
          @click="fanhui()">返回</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetBuildRoomList,
  SaveOutReporting,
  WeGetAppletOrganList,
  GetOrgansNullToken
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      // 健康码
      jkImageList: [],
      //行程码
      xcImageList: [],
      //核酸证明
      hsImageList: [],
      ridlist: [],
      BIdlist: [],
      BUIdlist: [],
      BFIdlist: [],
      BRIdlist: [],
      OrganList: [],
      diqu: [],
      guanxi: [],
      minzu: [],
      xveli: [],
      zhuangtai: [],
      hunyin: [],
      ocodelist: [],
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      NativeCantonCodedata: [],
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      yimiaolist: [
        { id: "0", Name: "未接种" },
        { id: "1", Name: "第一针" },
        { id: "2", Name: "第二针" },
      ],
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        ID: 0,  //id、
        Region: "",  // 小区(组)名称、
        Number: "",  // 房号、
        Name: "",  // 姓名、 
        Mobile: "",  // 电话、
        IDCard: "",  // 身份证号、
        OutTime: "",  // 出行时间、
        Vehicle: "",  // 交通工具、
        City: "",  // 目的地、
        Address: "",  // 重点停留地点、 
        BackTime: "",  // 放回时间、
        BackVehicle: "",  // 返回交通工具、
        IsPeer: 0,  // 是否有同行（0否、1是）、
        IsCheck: 0,  // 是否核算检测（0否、1是）、
        IsDivide: 0,  // 是否具有居家隔离条件（0否、1是）、 
        Count: "",  // 家住几人、
        Area: 0,  // 房屋面积、
        IsAlone: 0,  // 是否单独卫生间（0否、1是）、
        OpenID: "",  //、
        //健康码
        HealthCode: "",
        //行程码
        TravelCode: "",
        //核酸证明
        Testify: "",
      },
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromBackTime: false,
      datafromOutTime: false,
    };
  },
  created () {
    this.GetOrgansNullToken();
  },
  methods: {
    //上传健康码
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.HealthCode = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除健康码
    jkDelete (file) {
      this.jkImageList = []
      this.datafrom.HealthCode = ""
    },
    //上传行程码
    afterXcRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.TravelCode = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除行程码
    xcDelete (file) {
      this.xcImageList = []
      this.datafrom.TravelCode = ""
    },
    //上传核酸证明
    afterhsRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.Testify = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除核酸
    hsDelete (file) {
      this.hsImageList = []
      this.datafrom.Testify = ""
    },
    // 提交
    postfrom: function () {
      if (this.datafrom.Name == "") {
        Toast.fail("请输入姓名");
        return false;
      }
      if (this.datafrom.Region == "") {
        Toast.fail("请选择小区");
        return false;
      }
      // if (this.datafrom.Number == "") {
      //   Toast.fail("请输入房号");
      //   return false;
      // }
      if (this.datafrom.Mobile == "") {
        Toast.fail("请输入联系电话");
        return false;
      }
      if (this.datafrom.IDCard == "") {
        Toast.fail("请输入身份证号码");
        return false;
      }
      if (this.datafrom.Address == "") {
        Toast.fail("请输入重点停留地点");
        return false;
      }
      if (this.datafrom.OutTime == "") {
        Toast.fail("请选择前往时间");
        return false;
      }
      if (this.datafrom.Vehicle == "") {
        Toast.fail("请输入前往交通工具");
        return false;
      }
      if (this.datafrom.City == "") {
        Toast.fail("请输入前往目的");
        return false;
      }
      if (this.datafrom.BackTime == "") {
        Toast.fail("请选择返回时间");
        return false;
      }
      if (this.datafrom.BackVehicle == "") {
        Toast.fail("请输入返回交通工具");
        return false;
      }
      if (this.datafrom.IsPeer == "") {
        Toast.fail("选择是否有同行人员");
        return false;
      }
      if (this.datafrom.IsCheck == "") {
        Toast.fail("请选择是否核酸通过");
        return false;
      }
      // if (this.datafrom.IsDivide == "") {
      //   Toast.fail("请选择是否有居家隔离条件");
      //   return false;
      // }
      // if (this.datafrom.Count == "") {
      //   Toast.fail("请输入家庭人数");
      //   return false;
      // }
      // if (this.datafrom.HealthCode == "") {
      //   Toast.fail("请上传健康码");
      //   return false;
      // }
      // if (this.datafrom.TravelCode == "") {
      //   Toast.fail("请上传行程码");
      //   return false;
      // }
      // if (this.datafrom.Testify == "") {
      //   Toast.fail("请上传核酸证明");
      //   return false;
      // }
      this.datafrom.OpenID = getOpenId();
      SaveOutReporting(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            // this.$router.push({
            //   name: "register",
            // });
            this.$router.go(-1)
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      })
    },
    gitocodelist () {
      WeGetAppletOrganList().then((res) => {
        this.ocodelist = res.data.data;
      })
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    // 小区(组)列表
    RegionList: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.push({
        name: "register",
      });
    },
    // 社区选择器
    onidListOrg (val) {
      this.datafrom.organcode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
    // 疫苗选择器
    onyimiao (val) {
      this.datafrom.yimiao = val.id;
      this.datafrom.yimiaoName = val.Name;
      this.yimiao = false;
    },
    // 返回时间
    ondatafromBackTime (val) {
      this.datafrom.BackTime = this.dateformatDay(val);;
      this.datafromBackTime = false;
    },
    // 前进时间
    ondatafromOutTime (val) {
      this.datafrom.OutTime = this.dateformatTime(val);;
      this.datafromOutTime = false;
    },
    // 社区
    onocode (val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    //年月日
    dateformatDay (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      return `${year}-${month}-${day}`;
    },

    //年月日时分秒
    dateformatTime (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      return `${year}-${month}-${day} ${houser}:${Minutes}`;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>